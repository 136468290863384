import React, { useMemo } from "react"
import Select from 'react-select'
import { Link, navigate } from "gatsby"
import { getItem, isLoggedIn, logout } from "../../services/auth"
import { CreditCardIcon, KeyIcon, UserCircleIcon, UserGroupIcon, ViewGridAddIcon } from '@heroicons/react/outline'
import { GetRequestAuth, PostRequestAuth } from "../../services/api/backend";
import AlertSuccess from "../../components/alert/success";
import countryList from 'react-select-country-list'

const navigation = [
  { name: 'Account', href: '/member/account', icon: UserCircleIcon, current: false },
  { name: 'Password', href: '/member/password', icon: KeyIcon, current: true },
  { name: 'Plan & Billing', href: '#', icon: CreditCardIcon, current: false },
  { name: 'News Timeline Setting', href: '#', icon: UserGroupIcon, current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


class Password extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      password: "",
      password_confirmation: "",
      success_change: false,
      token: getItem("token")
    };
    this.onInputchange = this.onInputchange.bind(this);
    this.onSavePassword.bind(this);
  }

  onInputchange(event) {
    console.log("event : ", event);
    this.setState({
      [event.target.name]: event.target.value

    });
  }

  onSavePassword = (e) => {
    e.preventDefault();
    const data = {
      password: e.target.password.value,
      password_confirmation: e.target.password_confirmation.value
    }
    PostRequestAuth("user-password",this.state.token, data)
      .then(res => {
        if(res.data.success){
          this.setState(state => ({
            success_change: true
          }));
        }
      }).catch((err) => {

    });
  };

  render() {
  return (

    <>
      <div className='container border-b-2 border-primary mb-6'>
        <h1 className='p-6 text-3xl text-center uppercase text-skin-primary'>Your Crypto Profile</h1>
      </div>
      <div className="container">
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
          <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
            <nav className="space-y-1">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    item.current
                      ? 'bg-gray-50 text-indigo-700 hover:text-indigo-700 hover:bg-white'
                      : 'text-gray-900 hover:text-gray-900 hover:bg-gray-50',
                    'group rounded-md px-3 py-2 flex items-center text-sm font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  <item.icon
                    className={classNames(
                      item.current
                        ? 'text-indigo-500 group-hover:text-indigo-500'
                        : 'text-gray-400 group-hover:text-gray-500',
                      'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                    )}
                    aria-hidden="true"
                  />
                  <span className="truncate">{item.name}</span>
                </Link>
              ))}
            </nav>
          </aside>

          <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
            <form onSubmit={e => this.onSavePassword(e)} method="POST">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                  <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Password</h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Change your password
                    </p>
                    {this.state.success_change &&
                    <AlertSuccess title="Succes : Your password has been updated"/>
                    }
                  </div>

                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <label htmlFor="company-website" className="block text-sm font-medium text-gray-700">
                        Password
                      </label>
                      <div className="mt-1 rounded-md shadow-sm flex">
                        <input
                          type="password"
                          name="password"
                          id="password"
                          onChange={this.onInputchange}
                          className="p-2 border focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                        />
                      </div>
                    </div>

                    <div className="col-span-3 sm:col-span-2">
                      <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                        Confirm password
                      </label>
                      <div className="mt-1 rounded-md shadow-sm flex">
                        <input
                          type="password"
                          name="password_confirmation"
                          id="password_confirmation"
                          onChange={this.onInputchange}
                          className="p-2 border focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                        />
                      </div>
                      <p className="mt-2 text-sm text-gray-500">
                        Brief description for your profile. URLs are hyperlinked.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <button
                    type="submit"
                    className="bg-skin-primary border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* end  */}
      <div className="container mt-10">
        <div
          style={{
            display: "flex",
            flex: "1",
            justifyContent: "space-between",
            borderBottom: "1px solid #d1c1e0",
          }}
        >
        </div>
      </div>
    </>
    );
  }
}
export default Password;